@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css');



.open-sans-<uniquifier> {
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hover-gradient {
  position: relative;
  transition: all 0.5s ease-in-out;
  background-size: 200% 100%;
}

.hover-gradient2 {
  position: relative;
  transition: all 0.5s ease-in-out;
  background-size: 200% 100%;
}

.hover-gradient::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background: linear-gradient(to right, #fdbf37 0%, transparent 25%);
  transition: width 0.5s ease-in-out;
  z-index: -1;
}

.hover-gradient:hover::before {
  width: 100%;
}